/**
* @author zjc[beica1@outook.com]
* @date 2021/8/11 13:53
* @description
*   Trend.vue of WeTrade
*/
<template>
  <PageWithHeader>
    <template #title>
      <t path="indicatorsandsignals_2">Indicators and signals</t>
    </template>
    <div class="px-16">
      <div class="df-middle my-16">
        <img src="/img/membership/cover.jpg" width="64" height="64" style="object-fit: fill;" />
        <div class="flex-1 ml-16">
          <t as="p" path="indicatorsandsignals_11" class="f-lg f-bold mb-8">Trend</t>
          <t as="p" path="indicatorsandsignals_12" class="f-sm" style="line-height: 16px;">
            See the ups and downs of the trend intuitively
          </t>
          <div class="f-sm mt-8">
            <t path="indicatorsandsignals_15"></t>
            <router-link  to="/membership/level" class="c-asst ml-4">
              <t path="indicatorsandsignals_16" :args="{level: 1}"></t>
              <Icon name="help" class="ml-4" sm />
            </router-link>
          </div>
        </div>
      </div>
      <Line class="mb-12" />
    </div>
    <t #="{td, t}" custom>
      <Tab
        :tabs="[t('indicatorsandsignals_3') || 'Deacription', t('indicatorsandsignals_4') || 'Signals']"
        class="signal-page-tab px-16" @switch="switchTab" :active="tabIndex" />
    </t>
    <TheDescription class="pt-12 pb-24" v-if="tabIndex === 0" />
    <TheSignals class="pt-12 pb-24" v-else />
  </PageWithHeader>
</template>

<script lang="ts">
import Icon from '@/components/Icon.vue'
import { defineComponent, ref } from 'vue'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import Tab from '@/components/Tab.vue'
import Line from '@/components/Line.vue'
import TheDescription from '@/modules/membership/signal/components/TheDescription.vue'
import TheSignals from '@/modules/membership/signal/components/TheSignals.vue'

export default defineComponent({
  name: 'Trend',
  components: { Icon, TheSignals, TheDescription, Line, PageWithHeader, Tab },
  props: {
    id: String,
  },
  setup () {
    const tabIndex = ref(0)

    const switchTab = (index: number) => {
      tabIndex.value = index
    }

    return {
      switchTab,
      tabIndex,
    }
  },
})
</script>

<style scoped lang="scss">
.faq-list {
  background: var(--color-light);
  border-radius: 2px;
}

.tips {
  justify-content: flex-end;
  background: url('/img/help/banner@2x.png') center no-repeat;
  background-size: 100% 100%;
  color: var(--color-white);
  line-height: 20px;
  min-height: 64px;
}

.tips-text {
  min-width: 60%;
  max-width: 60%;
}

.signal-page-tab {
  ::v-deep(.active) {
    font-size: 17px;
  }
}
</style>
