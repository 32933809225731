
import List from '@/components/AsyncNullableList.vue'
import Icon from '@/components/Icon.vue'
import Line from '@/components/Line.vue'
import { openFullscreenList } from '@/components/popup/popup'
import { keymap } from '@/config'
import Switch from '@/modules/membership/signal/components/Switch.vue'
import TheSignalEditView from '@/modules/membership/signal/components/TheSignalEditView.vue'
import {
  isauth,
  readSignalsList,
  saveSignal,
  SignalAuthLevel,
  SignalsListSchema,
  SignalsSchema,
  SignalType,
} from '@/modules/membership/signal/signal.api'
import Holder from '@/provider/Holder.vue'
import HoldOn from '@/provider/HoldOn.vue'
import Matrix from 'common/Matrix.vue'
import { localSet } from 'essential/store/localStore'
import * as R from 'ramda'
import { computed, defineComponent, shallowRef, watch } from 'vue'
import { YesOrNo } from '@/types'
import state from '@/state'

const signals = [
  {
    i18nPath: 'indicatorsandsignals_9',
    title: 'Trend change between bullish and bearish on the 30-minute chart',
    id: SignalType.TC30M,
    accessLevel: 1,
  }, {
    i18nPath: 'indicatorsandsignals_10',
    title: 'Trend change between bullish and bearish on the 1-day chart',
    id: SignalType.TC1D,
    accessLevel: 1,
  }, {
    i18nPath: 'indicatorsandsignals_20',
    title: 'Enter the overbought/oversold area on the 30-minute chart',
    id: SignalType.TO30M,
    accessLevel: 2,
  }, {
    i18nPath: 'indicatorsandsignals_21',
    title: 'Enter the overbought/oversold area on the 1-day chart',
    id: SignalType.TO1D,
    accessLevel: 2,
  },
]

export default defineComponent({
  name: 'TheSignals',
  components: { Switch, Line, Icon, Matrix, HoldOn, Holder, List },
  setup () {
    const list = shallowRef<Array<SignalsListSchema>>([])
    const codeList = shallowRef<Array<string>>([])
    const typeList = shallowRef<Array<string>>([])
    const userAuthLevel = shallowRef<SignalAuthLevel>(SignalAuthLevel.FORBIDDEN)
    const isSignalAvailable = computed(() => userAuthLevel.value !== SignalAuthLevel.FORBIDDEN)
    const isPrime = computed(() => state.prime.member === YesOrNo.YES)

    watch(codeList, codes => {
      localSet(keymap.label.monitorList, codes.toString())
    })

    const edit = () => {
      openFullscreenList(TheSignalEditView, {
        onSave () {
          read()
        },
      })
    }

    const change = (isChecked: boolean, value: string) => {
      const lastTypes = typeList.value
      if (isChecked && !R.includes(value, lastTypes)) {
        lastTypes.push(value)
      } else {
        lastTypes.splice(R.indexOf(value)(lastTypes), 1)
      }
      const types = lastTypes.join(',')
      saveSignal({ types }).then(() => read())
    }

    const read = () => {
      readSignalsList().then((resp: SignalsSchema) => {
        codeList.value = resp?.codeList ?? []
        typeList.value = resp?.typeList ?? []

        list.value = R.map(signal => ({
          ...signal,
          isChecked: R.includes(String(signal.id), resp?.typeList ?? []),
          available: isPrime.value,
        }), signals)
      })
    }

    isauth().then(resp => {
      userAuthLevel.value = resp
      read()
    })

    return {
      codeList,
      edit,
      change,
      list,
      isSignalAvailable,
    }
  },
})
