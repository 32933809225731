/**
* @author 贝才[beica1@outook.com]
* @date 2021/7/6 14:22
* @description
*   TheSignalEditView.vue of wetrade
*/
<template>
  <FullScreen class="px-16 df-col">
    <PageHeader>
      <template #title>
        <t path="editmonitoring_2"></t>
      </template>
    </PageHeader>
    <Scroller css-only class="flex-1">
      <SymbolList #="{symbol}" @save="$emit('save')">
        <TheSymbolButton :symbol="symbol" />
      </SymbolList>
    </Scroller>
  </FullScreen>
</template>

<script lang="ts">
import PageHeader from '@/components/PageHeader.vue'
import FullScreen from '@/components/popup/FullScreen.vue'
import SymbolList from '@/modules/market/SymbolList.vue'
import TheSymbolButton from '@/modules/membership/signal/components/TheSymbolButton.vue'
import Scroller from 'common/providers/Scroller.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TheSignalEditView',
  components: { Scroller, TheSymbolButton, SymbolList, FullScreen, PageHeader },
})
</script>

<style scoped>

</style>
