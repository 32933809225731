/**
* @author 贝才[beica1@outook.com]
* @date 2021/2/19
* @description
*   Tab.vue of WeTrade
*/
<template>
  <ul class="tabs">
    <li
      v-for="(tab, index) in tabs" :key="tab && tab.value || tab"
      :class="['wt-tab-nav', { active: index === current}]"
      @click="change(index)"
    >
      <slot :tab="tab">{{tab && tab.label || tab}}</slot>
    </li>
  </ul>
</template>

<script lang="ts">
import { defineComponent, shallowRef } from 'vue'

export default defineComponent({
  name: 'Tab',
  props: {
    tabs: {
      type: Array,
      required: true,
    },
    active: {
      type: Number,
      default: 0,
    },
  },
  emits: ['switch'],
  setup (props, ctx) {
    const current = shallowRef(props.active)
    const change = (index: number) => {
      if (current.value !== index) {
        current.value = index
        ctx.emit('switch', index)
      }
    }

    return {
      current,
      change,
    }
  },
})
</script>

<style scoped lang="scss">
.tabs {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 48px;
  line-height: 48px;
  text-align: center;
}
</style>
