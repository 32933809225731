<template>
  <div class="px-16">
    <div class="pool">
      <div class="df-between df-middle mb-20">
        <t path="indicatorsandsignals_5" class="f-bold">Monitoring pool</t>
        <div v-if="isSignalAvailable" class="edit c-title f-sm df-middle" @click="edit">
          <t path="indicatorsandsignals_6">Edit</t>
          <Icon name="list" class="sm" />
        </div>
      </div>
      <HoldOn v-if="isSignalAvailable" :content="codeList" :height="80">
        <Matrix v-if="codeList.length" :list="codeList" #="{ item }">
          <p class="mb-8 t-center f-md pool-item">{{item}}</p>
        </Matrix>
        <p v-else class="c-tip f-md py-12 t-center mb-12">There is no data!</p>
      </HoldOn>
      <t as="div" path="indicatorsandsignals_14" class="no-auth f-md c-tip py-12 t-center mb-12"
         v-else>
        You are not qulified to use this indicator
      </t>
    </div>
    <div class="signals pt-12">
      <t path="indicatorsandsignals_7" as="p" class="f-bold">Signals</t>
      <div class="df-middle c-title f-sm my-8">
        <Icon name="warn" sm />
        <t path="indicatorsandsignals_8" class="flex-1 ml-6" style="font-style: italic;">
          Signals will be pushed by system push notification,
          please make sure that the notification is on
        </t>
      </div>
      <Holder :content="list">
        <List :list-data="list" #="{ item }">
          <div class="py-16 df-middle">
            <t as="p" :path="item.i18nPath" class="flex-1 mr-12 signal-title">{{item.title}}</t>
            <Switch
              :checked='item.isChecked' :disabled="!item.available"
              :value="item.id.toString()" @change="change"
            />
          </div>
          <Line />
        </List>
      </Holder>
    </div>
  </div>
</template>

<script lang="ts">
import List from '@/components/AsyncNullableList.vue'
import Icon from '@/components/Icon.vue'
import Line from '@/components/Line.vue'
import { openFullscreenList } from '@/components/popup/popup'
import { keymap } from '@/config'
import Switch from '@/modules/membership/signal/components/Switch.vue'
import TheSignalEditView from '@/modules/membership/signal/components/TheSignalEditView.vue'
import {
  isauth,
  readSignalsList,
  saveSignal,
  SignalAuthLevel,
  SignalsListSchema,
  SignalsSchema,
  SignalType,
} from '@/modules/membership/signal/signal.api'
import Holder from '@/provider/Holder.vue'
import HoldOn from '@/provider/HoldOn.vue'
import Matrix from 'common/Matrix.vue'
import { localSet } from 'essential/store/localStore'
import * as R from 'ramda'
import { computed, defineComponent, shallowRef, watch } from 'vue'
import { YesOrNo } from '@/types'
import state from '@/state'

const signals = [
  {
    i18nPath: 'indicatorsandsignals_9',
    title: 'Trend change between bullish and bearish on the 30-minute chart',
    id: SignalType.TC30M,
    accessLevel: 1,
  }, {
    i18nPath: 'indicatorsandsignals_10',
    title: 'Trend change between bullish and bearish on the 1-day chart',
    id: SignalType.TC1D,
    accessLevel: 1,
  }, {
    i18nPath: 'indicatorsandsignals_20',
    title: 'Enter the overbought/oversold area on the 30-minute chart',
    id: SignalType.TO30M,
    accessLevel: 2,
  }, {
    i18nPath: 'indicatorsandsignals_21',
    title: 'Enter the overbought/oversold area on the 1-day chart',
    id: SignalType.TO1D,
    accessLevel: 2,
  },
]

export default defineComponent({
  name: 'TheSignals',
  components: { Switch, Line, Icon, Matrix, HoldOn, Holder, List },
  setup () {
    const list = shallowRef<Array<SignalsListSchema>>([])
    const codeList = shallowRef<Array<string>>([])
    const typeList = shallowRef<Array<string>>([])
    const userAuthLevel = shallowRef<SignalAuthLevel>(SignalAuthLevel.FORBIDDEN)
    const isSignalAvailable = computed(() => userAuthLevel.value !== SignalAuthLevel.FORBIDDEN)
    const isPrime = computed(() => state.prime.member === YesOrNo.YES)

    watch(codeList, codes => {
      localSet(keymap.label.monitorList, codes.toString())
    })

    const edit = () => {
      openFullscreenList(TheSignalEditView, {
        onSave () {
          read()
        },
      })
    }

    const change = (isChecked: boolean, value: string) => {
      const lastTypes = typeList.value
      if (isChecked && !R.includes(value, lastTypes)) {
        lastTypes.push(value)
      } else {
        lastTypes.splice(R.indexOf(value)(lastTypes), 1)
      }
      const types = lastTypes.join(',')
      saveSignal({ types }).then(() => read())
    }

    const read = () => {
      readSignalsList().then((resp: SignalsSchema) => {
        codeList.value = resp?.codeList ?? []
        typeList.value = resp?.typeList ?? []

        list.value = R.map(signal => ({
          ...signal,
          isChecked: R.includes(String(signal.id), resp?.typeList ?? []),
          available: isPrime.value,
        }), signals)
      })
    }

    isauth().then(resp => {
      userAuthLevel.value = resp
      read()
    })

    return {
      codeList,
      edit,
      change,
      list,
      isSignalAvailable,
    }
  },
})
</script>

<style scoped lang="scss">
.pool-item {
  height: 28px;
  line-height: 28px;
  background: var(--color-light);
  border-radius: 2px;
}

.signal-title {
  line-height: 24px;
}

.no-auth {
  background: var(--color-background);
  border-radius: 2px;
  line-height: 16px;
}
</style>
