<template>
  <div class="text f-nm c-text px-16">
    <img src="/img/membership/content.jpg" style="margin-top: 0;" />
    <t path="indicatorsandsignals_13" multiline #="{td}" custom>
      <p v-for="(p, index) in td" :key="index">{{p}}</p>
    </t>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TheDescription',
})
</script>
<style scoped lang="scss">
  .text {
    line-height: 24px;
    img {
      display: block;
      width: 100%;
      margin: 12px 0;
    }
  }
</style>
