/**
* @author 贝才[beica1@outook.com]
* @date 2021/7/6 14:57
* @description
*   TheSymbolButton.vue of wetrade
*/
<template>
  <TheToggleStateButton :progress="progress" :on="added" @click="toggle">
    {{prop('code', symbol)}}
    <template #suffix>
      {{prop('tips', symbol)}}
    </template>
  </TheToggleStateButton>

</template>

<script lang="ts">
import TheToggleStateButton from '@/modules/market/components/TheToggleStateButton.vue'
import useToggleMonitor from '@/modules/membership/signal/useToggleMonitor'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TheSymbolButton',
  components: { TheToggleStateButton },
  props: {
    symbol: {
      type: Object,
      required: true,
    },
  },
  setup (props) {
    return useToggleMonitor(props.symbol.code as string)
  },
})
</script>

<style scoped>

</style>
